<template>
  <div class="control-validator" @input="controlInput" @change="controlChange">
    <slot :state="controlState" :feedback="controlFeedback"/>
  </div>
</template>

<script>
export default {
  name: 'ControlValidator',
  inheritAttrs: false,
  props: {
    validator: {
      type: Object,
      required: true
    },
    feedbacks: {
      type: Object,
      default: () => ({
        success: null,
        errors: {}
      })
    },
    supressInputEvent: {
      type: Boolean,
      default: false
    },
    supressChangeEvent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      control: {
        validator: this.validator,
        feedbacks: {
          success: this.feedbacks.success || null,
          errors: this.feedbacks.errors || {}
        }
      }
    }
  },
  computed: {
    controlState () {
      return Object.keys(this.control.validator.$params).length > 0 && this.control.validator.$dirty ? !this.control.validator.$error : null
    },
    controlFeedback () {
      const validations = Object.keys(this.control.validator.$params || {})
      const errorKey = validations.find(key => this.control.validator[key] === false) || null
      const feedback = errorKey ? this.control.feedbacks.errors[errorKey] || null : this.control.feedbacks.success

      return feedback
    }
  },
  methods: {
    controlInput (e) {
      if (!this.supressInputEvent) {
        this.control.validator.$touch()
      }
    },
    controlChange (e) {
      if (!this.supressChangeEvent) {
        this.control.validator.$touch()
      }
    }
  },
  watch: {
    validator () {
      this.control.validator = this.validator
    },
    feedbacks () {
      this.control.feedbacks.success = this.feedbacks.success || null
      this.control.feedbacks.errors = this.feedbacks.errors || {}
    }
  }
}
</script>

<style lang="scss"></style>
